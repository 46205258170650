import AuthService from "./api/AuthService";

export const createLtiConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/lti-consumers', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar consumidor', error);

        throw error;
    }
};

export const getLtiConsumers = async (payload, userToken) => {
    try {
        let url = '/lti-consumers?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        if (payload.companyId) {
            url += `company_id=${payload.companyId}&`;
        }

        const response = await AuthService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar consumidores', error);

        throw error;
    }
};

export const deleteLtiConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.delete(`/lti-consumers/${payload.ltiConsumerId}`, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao deletar consumidor', error);

        throw error;
    }
};

export const updateLtiConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.put(`/lti-consumers/${payload.ltiConsumerId}`, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar consumidor', error);

        throw error;
    }
}

export default {
    createLtiConsumer,
    getLtiConsumers,
    deleteLtiConsumer,
    updateLtiConsumer
}