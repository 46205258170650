import AuthService from "./api/AuthService";

export const createUser = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/users', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao criar usuário:', error);

        throw error;
    }
}

export const findUser = async (userId, userToken) => {
    try {
        const response = await AuthService.get('/users/' + userId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao processar a requisição de usuários:', error);

    throw error;
  }
};

export const updateUser = async (payload, userToken) => {
    try {
        const response = await AuthService.put('/users/' + payload.userId, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar informações do usuário:', error);

        throw error;
    }
}

export const deleteUser = async (userId, userToken) => {
    try {
        const response = await AuthService.delete('/users/' + userId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao deletar usuário:', error);

        throw error;
    }
}

export const getUsers = async (payload, userToken) => {
    try {
        let url = '/users?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        if (payload.role) {
            url += `role=${payload.role}&`;
        }

        if (payload.userResponsible) {
            url += `user_responsible=${payload.userResponsible}&`;
        }

        if (payload.disciplineId) {
            url += `discipline_id=${payload.disciplineId}&`;
        }

        if (payload.page) {
            url += `page=${payload.page}&`;
        }

        if (payload.perPage) {
            url += `per_page=${payload.perPage}&`;
        }

        const response = await AuthService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar usuários:', error);
        throw error;
    }
}

export const changePassword = async (payload, userToken) => {
    try {
        const response = await AuthService.post(`/users/${payload.userId}/change-password`, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao alterar senha:', error);

        throw error;
    }
}

export default {
    createUser,
    findUser,
    updateUser,
    deleteUser,
    getUsers,
    changePassword
}