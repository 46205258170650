import SuppliersService from "@/services/SuppliersService";

const state = {
    suppliers: []
};

const getters = {
    getSuppliers: state => state.suppliers
};

const actions = {
    async getSuppliers({ commit }, userToken) {
        try {
            const response = await SuppliersService.getSuppliers(userToken);

            commit('setSuppliers', response);
        } catch (error) {
            console.error(error);
        }
    }
};

const mutations = {
    setSuppliers: (state, suppliers) => {
        state.suppliers = suppliers;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};