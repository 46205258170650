import AuthService from "./api/AuthService";

export const getSuppliers = async (userToken) => {
    try {
        const response = await AuthService.get("/suppliers", {
            headers: {
                Authorization: "Bearer " + userToken,
            },
        });

        return response.data;
    } catch (error) {
        console.error("Erro ao consultar fornecedores", error);

        throw error;
    }
};

export default {
    getSuppliers,
};
