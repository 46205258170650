import MaterialsApiService from "./api/MaterialsApiService";

export const createMaterial = async (payload, userToken) => {
  const data = {
    name: payload.name,
    color: payload.color,
    discipline_id: payload.disciplineId
  }

  if (payload.image) {
    data.image = payload.image
  }

  try {
    const response = await MaterialsApiService.post('/materials', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar material', error);

    throw error;
  }
};

export const findMaterial = async (materialId, userToken) => {
  try {
    const response = await MaterialsApiService.get('/materials/' + materialId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar material', error);

    throw error;
  }
};

export const updateMaterial = async (payload, userToken) => {
  const formData = new FormData();
  formData.append('name', payload.name);
  formData.append('color', payload.color);
  formData.append('visible', payload.visible);

  if (payload.image) {
    formData.append('image', payload.image);
  }

  try {
    const response = await MaterialsApiService.post(`/materials/${payload.materialId}/update`, formData, {
      headers: {
        Authorization: 'Bearer ' + userToken,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar material', error);

    throw error;
  }
};

export const deleteMaterial = async (materialId, userToken) => {
  try {
    const response = await MaterialsApiService.delete('/materials/' + materialId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao deletar material', error);

    throw error;
  }
};

export const duplicateMaterial = async (materialId, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/materials/${materialId}/duplicate`, {}, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao duplicar material', error);

    throw error;
  }
};

export const getMaterials = async (payload, userToken) => {
  try {
    let url = '/materials?';

    if (payload.search) {
      url += `search=${payload.search}&`;
    }

    if (payload.disciplineId) {
      url += `discipline_id=${payload.disciplineId}&`;
    }

    const response = await MaterialsApiService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar materiais:', error);
    throw error;
  }
}

export const updateIndex = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/materials/${payload.materialId}/index`, payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar ordem do material', error);
    throw error;
  }
}

export default {
  createMaterial,
  findMaterial,
  updateMaterial,
  deleteMaterial,
  duplicateMaterial,
  getMaterials,
  updateIndex
}