import MaterialsApiService from "./api/MaterialsApiService";

export const getDisciplineInscriptions = async (payload, userToken) => {
    try {
        let url = '/discipline-inscriptions?';

        if (payload.name) {
            url += `name=${payload.name}&`;
        }

        if (payload.disciplineId) {
            url += `discipline_id=${payload.disciplineId}&`;
        }

        if (payload.userId) {
            url += `user_id=${payload.userId}&`;
        }

        if (payload.status) {
            url += `status=${payload.status}&`;
        }

        if (payload.page) {
            url += `page=${payload.page}&`;
        }

        if (payload.perPage) {
            url += `per_page=${payload.perPage}&`;
        }

        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar inscrição em disciplina', error);

        throw error;
    }
}

export const createDisciplineInscription = async (payload, userToken) => {
    const data = {
        'discipline_id': payload.disciplineId,
        'started_at': payload.startedAt,
        'finished_at': payload.finishedAt,
    }

    if (payload.userId) {
        data.user_id = payload.userId;
    }

    if (payload.usersIds) {
        data.users_ids = payload.usersIds;
    }

    try {
        const response = await MaterialsApiService.post('/discipline-inscriptions', data, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar inscrição em disciplina', error);

        throw error;
    }
}

export const deleteDisciplineInscription = async (payload, userToken) => {
    try {
        await MaterialsApiService.delete(`/discipline-inscriptions/${payload.disciplineInscriptionId}`, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });
    } catch (error) {
        console.error('Erro ao deletar inscrição em disciplina', error);

        throw error;
    }
}

export const updateDisciplineInscription = async (payload, userToken) => {
    const data = {
        'started_at': payload.startedAt,
        'finished_at': payload.finishedAt,
    }

    if (payload.userId) {
        data.user_id = payload.userId;
    }

    if (payload.disciplineId) {
        data.discipline_id = payload.disciplineId;
    }

    try {
        const response = await MaterialsApiService.post(`/discipline-inscriptions/${payload.disciplineInscriptionId}/update`, data, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar inscrição em disciplina', error);

        throw error;
    }
}

export default {
    getDisciplineInscriptions,
    createDisciplineInscription,
    deleteDisciplineInscription,
    updateDisciplineInscription
}