import DisciplineTagsService from "@/services/DisciplineTagsService";

export default {
    state() {
        return {
            disciplineTag: {
                name: "",
                color: "",
                companyId: "",
                isEditable: ""
            },
            disciplineTagsData: "",
            distinctTagsData: ""
        }
    },

    getters: {
        getDisciplineTagName: (state) => state.disciplineTag.name,
        getDisciplineTagColor: (state) => state.disciplineTag.color,
        getDisciplineTagCompanyId: (state) => state.disciplineTag.companyId,
    },

    mutations: {
        setDisciplineTagData(state, data) {
            state.disciplineTag.name = data.name;
            state.disciplineTag.color = data.color;
            state.disciplineTag.companyId = data.companyId;
            state.disciplineTag.isEditable = data.is_editable;
        },
        resetDisciplineTag(state) {
            state.disciplineTag = {
                name: "",
                color: "",
                companyId: "",
            };
        },
        setDisciplineTagsData(state, data) {
            state.disciplineTagsData = data
        },
        setDistinctTagsData(state, data) {
            state.distinctTagsData = data
        }
    },

    actions: {
        async createDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.createDisciplineTag(payload.disciplineTag, payload.userToken);

                commit('resetDisciplineTag');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar tag:', error);

                throw error;
            }
        },

        async findDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.findDisciplineTag(payload.disciplineTagId, payload.userToken);

                commit('setDisciplineTagData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tag:', error);

                throw new Error('Não foi possível consultar tag. Por favor, tente novamente.');
            }
        },

        async updateDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.updateDisciplineTag(payload.disciplineTag, payload.userToken);

                commit('setDisciplineTagData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar tag:', error);

                throw new Error('Não foi possível atualizar a tag. Por favor, tente novamente.');
            }
        },

        async deleteDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.deleteDisciplineTag(payload.disciplineTagId, payload.userToken);

                commit('resetDisciplineTag');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar tag:', error);

                throw new Error('Não foi possível deletar a tag. Por favor, tente novamente.');
            }
        },

        async getDisciplineTags({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.getDisciplineTags(payload.data, payload.userToken);

                commit('setDisciplineTagsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tags:', error);

                throw new Error('Não foi possível consultar as tags. Por favor, tente novamente.');
            }
        },

        async getDistinctTags({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.getDistinctTags(payload.data, payload.userToken);

                commit('setDistinctTagsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tags:', error);

                throw new Error('Não foi possível consultar as tags. Por favor, tente novamente.');
            }
        },
    }
}
