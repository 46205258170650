import QuizzesService, { deleteMany, fixQuestion } from "@/services/QuizzesService";

export default {
    state() {
        return {
            quiz: {
                title: "",
                text: "",
                image: "",
                disciplineId: ""
            },
            quizzesData: "",
            quizFileData: "",
            selectedQuizzes: [],
        }
    },

    mutations: {
        setQuizData(state, data) {
            state.quiz.name = data.name;
            state.quiz.text = data.text;
            state.quiz.image = data.image;
            state.quiz.disciplineId = data.discipline_id;
        },
        resetQuiz(state) {
            state.quiz = {
                title: "",
                text: "",
                image: "",
                disciplineId: "",
            };
        },
        setQuizzesData(state, data) {
            state.quizzesData = data
        },
        setQuizFileData(state, data) {
            state.quizFileData = data
        },
        success(state, message) {
            console.log(message);
        }
    },

    actions: {
        async createQuiz({ commit }, payload) {
            try {
                const response = await QuizzesService.createQuiz(payload.quiz, payload.userToken);

                commit('resetQuiz');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar questão:', error);

                throw new Error('Não foi possível adicionar a questão. Por favor, tente novamente.');
            }
        },

        async findQuiz({ commit }, payload) {
            try {
                const response = await QuizzesService.findQuiz(payload.quizId, payload.userToken);

                commit('setQuizData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar questão:', error);

                throw new Error('Não foi possível consultar questão. Por favor, tente novamente.');
            }
        },

        async updateQuiz({ commit }, payload) {
            try {
                const response = await QuizzesService.updateQuiz(payload.quiz, payload.userToken);

                commit('setQuizData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar questão:', error);

                throw new Error('Não foi possível atualizar a questão. Por favor, tente novamente.');
            }
        },

        async deleteQuiz({ commit }, payload) {
            try {
                const response = await QuizzesService.deleteQuiz(payload.quizId, payload.userToken);

                commit('resetQuiz');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar questão:', error);

                throw new Error('Não foi possível deletar a questão. Por favor, tente novamente.');
            }
        },

        async getQuizzes({ commit }, payload) {
            try {
                const response = await QuizzesService.getQuizzes(payload.data, payload.userToken);

                commit('setQuizzesData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar questões:', error);

                throw new Error('Não foi possível consultar as questões. Por favor, tente novamente.');
            }
        },

        async generateQuiz({ commit }, payload) {
            try {
                const response = await QuizzesService.generateQuiz(payload.data, payload.userToken);

                commit('resetQuiz');

                return response.data;
            } catch (error) {
                console.error('Erro ao gerar questionário:', error);

                throw error;
            }
        },

        async checkFile({ commit }, payload) {
            try {
                const response = await QuizzesService.checkFile(payload.data, payload.userToken);

                commit('setQuizFileData', response);

                return response;
            } catch (error) {
                console.error('Erro ao verificar arquivo:', error);

                throw error;
            }
        },

        async importByFile({ commit }, payload) {
            try {
                const response = await QuizzesService.importByFile(payload.data, payload.userToken);

                commit('success', 'Arquivo importado com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao importar arquivo:', error);

                throw error;
            }
        },

        async fixQuestion({ commit }, payload) {
            try {
                const response = await fixQuestion(payload.data, payload.userToken);

                commit('success', 'Questão corrigida com sucesso!');

                return response;
            } catch (error) {
                console.error('Erro ao corrigir questão:', error);

                throw error;
            }
        },

        async deleteMany({ commit }, payload) {
            try {
                const response = await deleteMany(payload.data, payload.userToken);

                commit('success', 'Questões deletadas com sucesso!');

                return response;
            } catch (error) {
                console.error('Erro ao deletar questões:', error);

                throw error;
            }
        },

        async exportQuizzes({ commit }, payload) {
            try {
                const response = await QuizzesService.exportQuizzes(payload.data, payload.userToken);

                commit('success', 'Questões exportadas com sucesso!');

                return response;
            } catch (error) {
                console.error('Erro ao exportar questões:', error);

                throw error;
            }
        }
    }
}
