import { createRouter, createWebHistory } from "vue-router";
import { useStore } from 'vuex';
import store from '@/store';
import axios from 'axios';
import tinycolor from 'tinycolor2';

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: "Login",
    },
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: "Login",
    },
  },

  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("@/views/ForgotPasswordView.vue"),
    meta: {
      title: "Recuperação de senha",
    },
  },

  {
    path: "/validate",
    name: "Validate Session",
    component: () => import("@/views/ValidateSessionView.vue"),
    meta: {
      title: "Validação",
    }
  },

  {
    path: "/not-found",
    name: "Not Found",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      title: "Não encontrado",
    }
  },

  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/NoLicenseView.vue"),
    meta: {
      title: "Erro ao acessar",
    }
  },

  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/UsersListView.vue"),
    meta: {
      title: "Usuários",
      requiresAuth: true,
    },
  },

  {
    path: "/users/create",
    name: "Create Users",
    component: () => import("@/views/CreateUserView.vue"),
    meta: {
      title: "Adicionar Usuário",
      requiresAuth: true,
    },
  },

  {
    path: "/users/:id/change-password",
    name: "Change Password",
    component: () => import("@/views/ChangePasswordView.vue"),
    meta: {
      title: "Alterar Senha",
      requiresAuth: true,
    },
  },

  {
    path: "/companies/create",
    name: "Create Companies",
    component: () => import("@/views/CreateCompanyView.vue"),
    meta: {
      title: "Adicionar Empresa",
      requiresAuth: true,
    },
  },

  {
    path: "/companies",
    name: "Companies",
    component: () => import("@/views/CompaniesListView.vue"),
    meta: {
      title: "Empresas",
      requiresAuth: true,
    },
  },

  {
    path: "/companies/:id/update",
    name: "Update Company",
    component: () => import("@/views/UpdateCompanyView.vue"),
    meta: {
      title: "Atualizar Empresa",
      requiresAuth: true
    }
  },

  {
    path: "/consumers/settings",
    name: "Update Consumer Settings",
    component: () => import("@/views/ConsumerSettingsView.vue"),
    meta: {
      title: "Atualizar dados do consumidor",
      requiresAuth: true
    }
  },

  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/AdminHomePageView.vue"),
    meta: {
      title: "Início",
      requiresAuth: true
    }
  },

  {
    path: "/products",
    name: "Products",
    component: () => import("@/views/ProductsView.vue"),
    meta: {
      title: "Produtos",
      requiresAuth: true,
    },
  },

  {
    path: "/integrations",
    name: "Integrations",
    component: () => import("@/views/IntegrationsView.vue"),
    meta: {
      title: "Integrações",
      requiresAuth: true
    }
  },

  {
    path: "/my-disciplines",
    name: "My personalized disciplines",
    component: () => import("@/views/ConsumerDisciplinesView.vue"),
    meta: {
      title: "Minhas Disciplinas",
      requiresAuth: true
    }
  },

  {
    path: "/my/disciplines",
    name: "My Disciplines",
    component: () => import("@/views/MyDisciplinesView.vue"),
    meta: {
      title: "Minhas Disciplinas",
      requiresAuth: true,
    },
  },

  {
    path: "/disciplines",
    name: "Disciplines",
    component: () => import("@/views/DisciplinesView.vue"),
    meta: {
      title: "Disciplinas",
      requiresAuth: true,
    },
  },

  {
    path: "/discipline/:id/materials",
    name: "Discipline Material",
    component: () => import("@/views/MaterialsDisciplineView.vue"),
    meta: {
      title: "Materiais",
      requiresAuth: true,
    },
  },

  {
    path: "/discipline/:id/class",
    name: "Discipline Class",
    component: () => import("@/views/ClassDisciplineView.vue"),
    meta: {
      title: "Turma",
      requiresAuth: true,
    },
  },

  {
    path: "/discipline/:id/grades",
    name: "Discipline Grades",
    component: () => import("@/views/GradesDisciplineView.vue"),
    meta: {
      title: "Notas",
      requiresAuth: true,
    },
  },

  {
    path: "/discipline/:id/materials/:materialItemId/quiz",
    name: "Questionnaire",
    component: () => import("@/views/QuestionnaireView.vue"),
    meta: {
      title: "Questionário",
      requiresAuth: true,
    },
  },

  {
    path: "/discipline/:id/materials/:materialItemId/quiz/attempt/:attemptId",
    name: "Questionnaire Attempt",
    component: () => import("@/views/QuestionnaireAttemptView.vue"),
    meta: {
      title: "Questionário",
      requiresAuth: true,
      internalRoute: true
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.internalRoute) {
        next();
      } else {
        next({ name: "Questionnaire" });
      }
    }
  },

  {
    path: "/discipline/:id/materials/:materialItemId/quiz/attempt/:attemptId/feedback",
    name: "Questionnaire Feedback",
    component: () => import("@/views/QuestionnaireFeedbackView.vue"),
    meta: {
      title: "Feedback do Questionário",
      requiresAuth: true,
      internalRoute: true
    },
    beforeEnter: (to, from, next) => {
      if (from.name === null) {
        next({ name: "Questionnaire Attempt" });
      } else if (to.meta.internalRoute) {
        next();
      } else {
        next({ name: "Questionnaire Attempt" });
      }
    }
  },

  {
    path: "/discipline/:id/materials/:materialItemId/quiz/answers",
    name: "Questionnaire Answers",
    component: () => import("@/views/QuestionnaireAnswersView.vue"),
    meta: {
      title: "Tentativas do Questionário",
      requiresAuth: true,
      internalRoute: true
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.internalRoute) {
        next();
      } else {
        next({ name: "Questionnaire" });
      }
    }
  },

  {
    path: "/quizzes/:id",
    name: "Quizzes",
    component: () => import("@/views/QuizzesView.vue"),
    meta: {
      title: "Banco de Questões",
      requiresAuth: true
    }
  },
  {
    path: "/quizzes/:id/create",
    name: "Create Quiz",
    component: () => import("@/views/CreateQuizView.vue"),
    meta: {
      title: "Criar Questão",
      requiresAuth: true
    }
  },
  {
    path: "/quizzes/:id/update",
    name: "Update Quiz",
    component: () => import("@/views/UpdateQuizView.vue"),
    meta: {
      title: "Atualizar Questão",
      requiresAuth: true
    }
  },
  {
    path: "/calendar",
    name: "Calendar View",
    component: () => import("@/views/CalendarStudentView.vue"),
    meta: {
      title: "Calendário",
      requiresAuth: true
    }
  },
];

const getSubdomain = () => {
  const host = window.location.host;

  return host.split('.')[0];
};

const fetchConsumerSettings = async (subdomain) => {
  //console.log('Subdomain: ', subdomain, ' Url: ', process.env.VUE_APP_APPLICATION_SECURITY + subdomain + '.' + process.env.VUE_APP_ARANDU_API_URL + '/settings');
  try {
    const response = await axios.get(process.env.VUE_APP_APPLICATION_SECURITY + subdomain + '.' + process.env.VUE_APP_ARANDU_API_URL + '/settings');
    return { data: response.data, status: response.status };
  } catch (error) {
    console.error('Erro ao obter as configurações do consumidor:', error);
    return { data: null, status: error.response ? error.response.status : 500 };
  }
};

const setApplicationColors = async (settings) => {
  const primaryLight = tinycolor(settings.primary_color).darken(1).toString();
  const primaryLighter = getPrimaryLighter(settings.primary_color);
  const primaryMedium = tinycolor(settings.primary_color).lighten(1).toString();
  const primaryDark = tinycolor(settings.primary_color).lighten(10).toString();
  const primaryDarker = tinycolor(settings.primary_color).darken(40).toString();

  const secondaryLight = tinycolor(settings.secondary_color).lighten(20).toString();
  const secondaryLighter = tinycolor(settings.secondary_color).lighten(40).toString();
  const secondaryDark = tinycolor(settings.secondary_color).lighten(10).toString();
  const secondaryDarker = tinycolor(settings.secondary_color).darken(40).toString();

  const root = document.documentElement;

  root.style.setProperty('--blue-0', settings.primary_color);
  root.style.setProperty('--blue-1', primaryDark);
  root.style.setProperty('--blue-2', primaryLight);
  root.style.setProperty('--blue-3', primaryDarker);
  root.style.setProperty('--blue-4', primaryLighter);
  root.style.setProperty('--blue-5', primaryMedium);

  root.style.setProperty('--orange-0', settings.secondary_color);
  root.style.setProperty('--orange-1', secondaryDark);
  root.style.setProperty('--orange-2', secondaryLight);
  root.style.setProperty('--orange-3', secondaryDarker);
  root.style.setProperty('--orange-4', secondaryLighter);
}

const getPrimaryLighter = (color) => {
  if (color === '#1A5276' || color === '#23761a') {
    return tinycolor(color).lighten(65).toString()
  }
  return tinycolor(color).lighten(45).toString()
}

const increaseExpirationTime = () => {
  const expirationSeconds = 3600 * 4;
  const newExpirationTime = new Date(new Date().getTime() + expirationSeconds * 15000);

  localStorage.setItem('expirationTime', newExpirationTime);
}

const authToken = (store) => {
  const token = store.getters.getToken;

  if (localStorage.getItem('expirationTime')) {
    const expirationTime = new Date(localStorage.getItem('expirationTime'));

    if (token && new Date() < expirationTime) {
      return token;
    } else {
      localStorage.removeItem('expirationTime');
      return null;
    }
  }

  increaseExpirationTime()

  return token ?? null;
};

const isTokenExpiringSoon = () => {
  const expirationTime = 300;

  if (expirationTime) {
    const currentTime = new Date(localStorage.getItem('expirationTime'));
    const tokenExpiration = new Date(new Date().getTime() + expirationTime * 15000);
    const differenceInSeconds = (currentTime - tokenExpiration) / 15000;

    return differenceInSeconds < 300;
  }
  return false;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  store.dispatch('setLoading', true);

  document.title = to.meta.title || "Guairacá Edtech";

  const subdomain = getSubdomain();

  const { data: settings, status } = await fetchConsumerSettings(subdomain);

  if (settings !== null) {
    store.dispatch('setConsumerSettings', settings);
    await setApplicationColors(settings.settings);
  }

  const isAuthenticated = authToken(store);

  if (status === 404 || !settings) {
    next('/error');
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresAuth && isTokenExpiringSoon()) {
    increaseExpirationTime();
  } else {
    next();
  }
});

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch('setLoading', false);
  }, 500);
});

export default router;
