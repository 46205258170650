import QuestionOptionsService from "@/services/QuestionOptionsService";

export default {
    state() {
        return {
            questionOption: {
                text: "",
                image: "",
                correctAnswer: "",
                quizId: ""
            },
            questionOptionData: ""
        }
    },

    mutations: {
        setQuestionOptionData(state, data) {
            state.questionOption.text = data.text;
            state.questionOption.image = data.image;
            state.questionOption.correctAnswer = data.correct_answer;
            state.questionOption.quizId = data.quiz_id;
        },
        resetQuestionOption(state) {
            state.questionOption = {
                text: "",
                image:  "",
                correctAnswer: "",
                quizId: "",
            };
        },
        setQuestionOptionsData(state, data) {
            state.questionOptionData = data
        }
    },

    actions: {
        async createQuestionOption({ commit }, payload) {
            try {
                const response = await QuestionOptionsService.createQuestionOption(payload.questionOption, payload.userToken);

                commit('resetQuestionOption');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar alternativa:', error);

                throw new Error('Não foi possível adicionar a alternativa. Por favor, tente novamente.');
            }
        },

        async findQuestionOption({ commit }, payload) {
            try {
                const response = await QuestionOptionsService.findQuestionOption(payload.questionOptionId, payload.userToken);

                commit('setQuestionOptionData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar alternativa:', error);

                throw new Error('Não foi possível consultar alternativa. Por favor, tente novamente.');
            }
        },

        async updateQuestionOption({ commit }, payload) {
            try {
                const response = await QuestionOptionsService.updateQuestionOption(payload.questionOption, payload.userToken);

                commit('setQuestionOptionData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar alternativa:', error);

                throw new Error('Não foi possível atualizar a alternativa. Por favor, tente novamente.');
            }
        },

        async deleteQuestionOption({ commit }, payload) {
            try {
                const response = await QuestionOptionsService.deleteQuestionOption(payload.questionOptionId, payload.userToken);

                commit('resetQuestionOption');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar alternativa:', error);

                throw new Error('Não foi possível deletar a alternativa. Por favor, tente novamente.');
            }
        },

        async getQuestionOptions({ commit }, payload) {
            try {
                const response = await QuestionOptionsService.getQuestionOptions(payload.data, payload.userToken);

                commit('setQuestionOptionsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar alternativas:', error);

                throw new Error('Não foi possível consultar as alternativas. Por favor, tente novamente.');
            }
        },
    }
}