import ConsumerDisciplinesService, { detachDisciplineFromConsumer } from "@/services/ConsumerDisciplinesService";

export default {
    state() {
        return {
            consumerDisciplines: {}
        }
    },

    getters: {
        getConsumerDisciplines: (state) => state.consumerDisciplines
    },

    mutations: {
        setConsumerDisciplines(state, data) {
            state.consumerDisciplines = data;
        },
        resetConsumerDisciplines(state) {
            console.log('resetConsumerDisciplines', state);
        },
        updateConsumerDisciplineUnits(state, { consumerDisciplineId }) {
            const discipline = state.consumerDisciplines.find(d => d.id === consumerDisciplineId);

            if (discipline) {
                discipline.urls.push(discipline.name);
            }
        },
        removeConsumerDisciplineUnit(state, { consumerDisciplineId, disciplineId }) {
            const discipline = state.consumerDisciplines.find(d => d.id === consumerDisciplineId);

            if (discipline) {
                discipline.urls = discipline.urls.filter(d => d.id !== disciplineId);
            }
        }
    },

    actions: {
        async getConsumerDisciplines({ commit }, payload) {
            try {
                const response = await ConsumerDisciplinesService.getConsumerDisciplines(payload.data, payload.userToken);

                commit('setConsumerDisciplines', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar disciplinas do consumidor:', error);
                throw error;
            }
        },

        async createConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.createConsumerDiscipline(payload.data, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao criar disciplina do consumidor:', error);
                throw error;
            }
        },

        async updateConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.updateConsumerDiscipline(payload.data, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao atualizar disciplina do consumidor:', error);
                throw error;
            }
        },

        async attachDisciplineToConsumer({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.attachDisciplineToConsumer(payload.data, payload.userToken);

                commit('updateConsumerDisciplineUnits', { consumerDisciplineId: payload.data.consumerDisciplineId });
            } catch (error) {
                console.error('Erro ao adicionar disciplina ao consumidor:', error);
                throw error;
            }
        },

        async detachDisciplineFromConsumer({ commit }, payload) {
            try {
                await detachDisciplineFromConsumer(payload, payload.userToken);

                commit('removeConsumerDisciplineUnit', { consumerDisciplineId: payload.consumerDisciplineId, disciplineId: payload.disciplineId });
            } catch (error) {
                console.error('Erro ao remover disciplina do consumidor:', error);
                throw error;
            }
        },

        async deleteConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.deleteConsumerDiscipline(payload.consumerDisciplineId, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao deletar disciplina do consumidor:', error);
                throw error;
            }
        },

        async copyConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.copyConsumerDiscipline(payload, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao copiar disciplina do consumidor:', error);
                throw error;
            }
        },
        
        async approveConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.approveConsumerDiscipline(payload.consumerDisciplineId, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao aprovar disciplina do consumidor:', error);
                throw error;
            }
        },

        async disapproveConsumerDiscipline({ commit }, payload) {
            try {
                await ConsumerDisciplinesService.disapproveConsumerDiscipline(payload.consumerDisciplineId, payload.userToken);

                commit('resetConsumerDisciplines');
            } catch (error) {
                console.error('Erro ao reabrir disciplina do consumidor:', error);
                throw error;
            }
        },
    }
}
