import AuthService from "./api/AuthService";

export const createCompany = async (payload, userToken) => {
  try {
    const response = await AuthService.post('/companies', payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar empresa', error);

    throw error;
  }
};

export const findCompany = async (companyId, userToken) => {
  try {
    const response = await AuthService.get('/companies/' + companyId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar empresa', error);

    throw error;
  }
};

export const updateCompany = async (payload, userToken) => {
  try {
    const response = await AuthService.put('/companies/' + payload.companyId, payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar empresa', error);

    throw error;
  }
};

export const deleteCompany = async (companyId, userToken) => {
  try {
    const response = await AuthService.delete('/companies/' + companyId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao deletar empresa', error);

    throw error;
  }
};

export const getCompanies = async (payload, userToken) => {
  try {
    let url = '/companies?';

    if (payload.search) {
      url += `search=${payload.search}&`;
    }

    if (payload.responsibleId) {
      url += `responsible_id=${payload.responsibleId}&`;
    }

    if (payload.status) {
      url += `status=${payload.status}&`;
    }

    const response = await AuthService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar empresas:', error);
    throw error;
  }
}

export const restoreCompany = async (companyId, userToken) => {
  try {
    const response = await AuthService.post(`/companies/${companyId}/restore`, null, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao restaurar empresa', error);

    throw error;
  }
};

export const getCompanyByConsumer = async (domain, userToken) => {
  try {
    const response = await AuthService.get(`/companies/consumer/${domain}`, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar empresa', error);

    throw error;
  }
};

export default {
  createCompany,
  findCompany,
  updateCompany,
  deleteCompany,
  getCompanies,
  restoreCompany,
  getCompanyByConsumer
}