import DisciplinesService from "@/services/DisciplinesService";

export default {
    state() {
        return {
            discipline: {
                id: "",
                name: "",
                companyId: "",
                visible: true,
                topics: [],
                tags: [],
                materials: [],
                inscriptions: [],
                isEditable: false,
                canGenerateTopics: false,
                owner: []
            },
            disciplinesData: "",

            duplicatedDisciplineData: "",

            disciplineAnalysisData: ""
        }
    },

    getters: {
        getDisciplineName: (state) => state.discipline.name,
        getDisciplineCompanyId: (state) => state.discipline.companyId,
    },

    mutations: {
        setDisciplineData(state, data) {
            state.discipline.id = data.id;
            state.discipline.name = data.name;
            state.discipline.companyId = data.company_id;
            state.discipline.visible = data.visible;
            state.discipline.topics = data.topics;
            state.discipline.tags = data.tags;
            state.discipline.materials = data.materials;
            state.discipline.inscriptions = data.inscriptions;
            state.discipline.isEditable = data.is_editable;
            state.discipline.canGenerateTopics = data.can_generate_topics;
            state.discipline.owner = data.owner;
        },
        resetDiscipline(state) {
            state.discipline = {
                name: "",
                companyId: null,
            };
        },
        setDisciplinesData(state, data) {
            state.disciplinesData = data
        },

        setDuplicatedDisciplineData(state, data) {
            state.duplicatedDisciplineData = data
        },

        setDisciplineAnalysisData(state, data) {
            state.disciplineAnalysisData = data
        }
    },

    actions: {
        async createDiscipline({ commit }, payload) {
            try {
                const response = await DisciplinesService.createDiscipline(payload.discipline, payload.userToken);

                commit('resetDiscipline');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar disciplina:', error);

                throw error;
            }
        },

        async findDiscipline({ commit }, payload) {
            try {
                const response = await DisciplinesService.findDiscipline(payload.disciplineId, payload.userToken);

                commit('setDisciplineData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar disciplina:', error);

                throw new Error('Não foi possível consultar disciplina. Por favor, tente novamente.');
            }
        },

        async updateDiscipline({ commit }, payload) {
            try {
                const response = await DisciplinesService.updateDiscipline(payload.discipline, payload.userToken);

                commit('setDisciplineData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar disciplina:', error);

                throw new Error('Não foi possível atualizar a disciplina. Por favor, tente novamente.');
            }
        },

        async deleteDiscipline({ commit }, payload) {
            try {
                const response = await DisciplinesService.deleteDiscipline(payload.disciplineId, payload.userToken);

                commit('resetDiscipline');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar disciplina:', error);

                throw new Error('Não foi possível deletar a disciplina. Por favor, tente novamente.');
            }
        },

        async getDisciplines({ commit }, payload) {
            try {
                const response = await DisciplinesService.getDisciplines(payload.data, payload.userToken);

                commit('setDisciplinesData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar disciplinas:', error);

                throw new Error('Não foi possível consultar as disciplinas. Por favor, tente novamente.');
            }
        },

        async insertTags({ commit }, payload) {
            try {
                const response = await DisciplinesService.insertTags(payload.data, payload.userToken);

                commit('resetDiscipline');

                return response.data;
            } catch (error) {
                console.error('Erro ao inserir tags:', error);

                throw new Error('Não foi possível inserir tags. Por favor, tente novamente.');
            }
        },

        async removeTag({ commit }, payload) {
            try {
                const response = await DisciplinesService.removeTag(payload.data, payload.userToken);

                commit('resetDiscipline');

                return response.data;
            } catch (error) {
                console.error('Erro ao remover tag:', error);

                throw new Error('Não foi possível remover tag. Por favor, tente novamente.');
            }
        },

        async duplicateDiscipline({ commit }, payload) {
            try {
                const response = await DisciplinesService.duplicateDiscipline(payload.disciplineId, payload.userToken);

                commit('setDuplicatedDisciplineData');

                return response.data;
            } catch (error) {
                console.log('Erro ao duplicar disciplina:', error);

                throw new Error('Não foi possível duplicar disciplina. Por favor, tente novamente.');
            }
        },

        async disciplineAnalysis({ commit }, payload) {
            try {
                const response = await DisciplinesService.disciplineAnalysis(payload.data, payload.userToken);

                commit('setDisciplineAnalysisData', response);

                return response.data;
            } catch (error) {
                console.error('Erro ao analisar ementa:', error);

                throw new Error('Não foi possível analisar ementa. Por favor, tente novamente.');
            }
        }
    }
}
