const loadingModule = {
    state: {
        isLoading: false,
        consumerSettings: {},
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.isLoading = payload;
        },

        setConsumerSettings(state, data) {
            state.consumerSettings = data;
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('SET_LOADING', isLoading);
        },

        setConsumerSettings({ commit }, data) {
            commit('setConsumerSettings', data);
        },
    },
    getters: {
        isLoading: state => state.isLoading,

        getConsumerSettings: state => state.consumerSettings,
    }
};

export default loadingModule;