import QuizApiService from "./api/QuizApiService";

export const createFeedback = async (payload, userToken) => {
    try {
      const response = await QuizApiService.post('/feedbacks', {
        text: payload.text,
        image: payload.image,
        quiz_id: payload.quizId
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao adicionar feedback', error);
  
      throw error;
    }
  };
  
  export const findFeedback = async (feedbackId, userToken) => {
    try {
      const response = await QuizApiService.get('/feedbacks/' + feedbackId, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao consultar feedback', error);
  
      throw error;
    }
  };
  
  export const updateFeedback = async (payload, userToken) => {
    try {
      const response = await QuizApiService.post(`/feedbacks/${payload.feedbackId}/update`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar feedback', error);
  
      throw error;
    }
  };
  
  export const deleteFeedback = async (feedbackId, userToken) => {
    try {
      const response = await QuizApiService.delete('/feedbacks/' + feedbackId, {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar feedback', error);
  
      throw error;
    }
  };
  
  export const getFeedbacks = async (payload, userToken) => {
    try {
      let url = '/feedbacks?';
  
      if (payload.search) {
        url += `search=${payload.search}&`;
      }
  
      if (payload.disciplineId) {
        url += `quiz_id=${payload.quizId}&`;
      }
  
      const response = await QuizApiService.get(url.slice(0, -1), {
        headers: {
          Authorization: 'Bearer ' + userToken
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Erro ao consultar feedbacks:', error);
      throw error;
    }
  }
  
  export default {
    createFeedback,
    findFeedback,
    updateFeedback,
    deleteFeedback,
    getFeedbacks,
  }