import MaterialsApiService from "./api/MaterialsApiService";

export const createDisciplineTag = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/discipline-tags', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar tag', error);

        throw error;
    }
};

export const findDisciplineTag = async (disciplineTagId, userToken) => {
    try {
        const response = await MaterialsApiService.get('/discipline-tags/' + disciplineTagId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar tag', error);

        throw error;
    }
};

export const updateDisciplineTag = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.put('/discipline-tags/' + payload.disciplineTagId, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar tag', error);

        throw error;
    }
};

export const deleteDisciplineTag = async (disciplineTagId, userToken) => {
    try {
        const response = await MaterialsApiService.delete('/discipline-tags/' + disciplineTagId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao deletar tag', error);

        throw error;
    }
};

export const getDisciplineTags = async (payload, userToken) => {
    try {
        let url = '/discipline-tags?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar tags:', error);
        throw error;
    }
}

export const getDistinctTags = async (payload, userToken) => {
    try {
        let url = `/discipline-tags/${payload.disciplineId}/distinct-tags?`;

        if (payload.search) {
            url += `search=${payload.search}`
        }

        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao obter tags:', error);
        throw error;
    }
}

export default {
    createDisciplineTag,
    findDisciplineTag,
    updateDisciplineTag,
    deleteDisciplineTag,
    getDisciplineTags,
    getDistinctTags
}
