import MaterialsApiService from "./api/MaterialsApiService";

export const createConsumerDiscipline = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/consumer-disciplines', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar disciplina do consumidor', error);

        throw error;
    }
}

export const findConsumerDiscipline = async (consumerDisciplineId, userToken) => {
    try {
        const response = await MaterialsApiService.get('/consumer-disciplines/' + consumerDisciplineId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar disciplina do consumidor', error);

        throw error;
    }
};

export const updateConsumerDiscipline = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.put('/consumer-disciplines/' + payload.consumerDisciplineId, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar disciplina do consumidor', error);

        throw error;
    }
};

export const deleteConsumerDiscipline = async (consumerDisciplineId, userToken) => {
    try {
        const response = await MaterialsApiService.delete('/consumer-disciplines/' + consumerDisciplineId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao excluir disciplina do consumidor', error);

        throw error;
    }
};

export const getConsumerDisciplines = async (payload, userToken) => {
    let url = '/consumer-disciplines?';

    if (payload.search) {
        url += `search=${payload.search}&`;
    }

    if (payload.type) {
        url += `type=${payload.type}&`;
    }

    if (payload.status) {
        url += `status=${payload.status}&`;
    }

    if (payload.page) {
        url += `page=${payload.page}&`;
    }

    if (payload.perPage) {
        url += `per_page=${payload.perPage}&`;
    }

    try {
        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao listar disciplinas do consumidor', error);

        throw error;
    }
};

export const attachDisciplineToConsumer = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post(`/consumer-disciplines/${payload.consumerDisciplineId}/discipline/${payload.disciplineId}/attach`, [], {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao vincular disciplina ao consumidor', error);

        throw error;
    }
};

export const detachDisciplineFromConsumer = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post(`/consumer-disciplines/${payload.consumerDisciplineId}/discipline/${payload.disciplineId}/detach`, [], {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao desvincular disciplina ao consumidor', error);

        throw error;
    }
};

export const copyConsumerDiscipline = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post(`/consumer-disciplines/${payload.consumerDisciplineId}/copy`, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao copiar disciplina do consumidor', error);

        throw error;
    }
};

export const approveConsumerDiscipline = async (consumerDisciplineId, userToken) => {
    try {
        const response = await MaterialsApiService.post(`/consumer-disciplines/${consumerDisciplineId}/approve`, {}, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao aprovar disciplina do consumidor', error);

        throw error;
    }
};

export const disapproveConsumerDiscipline = async (consumerDisciplineId, userToken) => {
    try {
        const response = await MaterialsApiService.post(`/consumer-disciplines/${consumerDisciplineId}/disapprove`, {}, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao reprovar disciplina do consumidor', error);

        throw error;
    }
};

export default {
    createConsumerDiscipline,
    findConsumerDiscipline,
    updateConsumerDiscipline,
    deleteConsumerDiscipline,
    getConsumerDisciplines,
    attachDisciplineToConsumer,
    detachDisciplineFromConsumer,
    copyConsumerDiscipline,
    approveConsumerDiscipline,
    disapproveConsumerDiscipline
};
