import ConsumerSettingsService from "@/services/ConsumerSettingsService";

export default {
    state() {
        return {
            status: null
        }
    },
    
    mutations: {
        success(state, message) {
            state.status = { type: 'success', message };
        },
    },
    actions: {
        async getConsumerSettings({ commit }, payload) {
            try {
                const response = await ConsumerSettingsService.getConsumerSettings(payload.userToken);

                commit('setConsumerSettings', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar configurações do consumidor:', error);
                throw error;
            }
        },

        async updateConsumerSettings({ commit }, payload) {
            try {
                const response = await ConsumerSettingsService.updateConsumerSettings(payload.data, payload.userToken);

                commit('success', 'Configurações atualizadas com sucesso!');

                return response;
            } catch (error) {
                console.error('Erro ao atualizar configurações do consumidor:', error);
                throw error;
            }
        }
    }
}