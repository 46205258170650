import AuthService from "./api/AuthService";

export const getConsumers = async (payload, userToken) => {
    try {
        let url = '/consumers?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        if (payload.companyId) {
            url += `company_id=${payload.companyId}&`;
        }

        if (payload.distinctResposibleId) {
            url += `distinct_responsible_id=${payload.distinctResposibleId}&`;
        }

        const response = await AuthService.get(url.slice(0, -1), {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar consumidores', error);

        throw error;
    }
}


export const createConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/consumers', payload, {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar consumidor', error);

        throw error;
    }
}

export default {
    getConsumers,
    createConsumer
}