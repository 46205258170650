import AttemptsService from "@/services/AttemptsService";

export default {
    state() {
        return {
            attempts: [],
            attempt: {},
        }
    },

    getters: {
        getAttempts: (state) => state.attempts,
        getAttempt: (state) => state.attempt,
    },

    mutations: {
        setAttempts(state, data) {
            state.attempts = data;
        },
        setAttempt(state, data) {
            state.attempt = data;
        },
        resetAttempt(state) {
            state.attempt = {};
        },
    },

    actions: {
        async findAttempt({ commit }, payload) {
            try {
                const response = await AttemptsService.findAttempt(payload.data, payload.userToken);

                commit('setAttempt', response.data);
            } catch (error) {
                console.error('Erro ao buscar tentativa', error);

                throw error;
            }
        },

        async startAttempt({ commit }, payload) {
            try {
                const response = await AttemptsService.startAttempt(payload.data, payload.userToken);

                commit('setAttempt', response);

                return response;
            } catch (error) {
                console.error('Erro ao iniciar tentativa', error);

                throw error;
            }
        },

        async finishAttempt({ commit }, payload) {
            try {
                const response = await AttemptsService.finishAttempt(payload.data, payload.userToken);

                commit('setAttempt', response.data);
            } catch (error) {
                console.error('Erro ao finalizar tentativa', error);

                throw error;
            }
        },

        async checkAttempt({ commit }, payload) {
            try {
                const response = await AttemptsService.checkAttempt(payload.data, payload.userToken);

                commit('setAttempt', response.data);
            } catch (error) {
                console.error('Erro ao verificar tentativa', error);

                throw error;
            }
        },

        async answerAttempt({ commit }, payload) {
            try {
                const response = await AttemptsService.answerAttempt(payload.data, payload.userToken);

                commit('setAttempt', response.data);
            } catch (error) {
                console.error('Erro ao responder tentativa', error);

                throw error;
            }
        },

        async deleteAttempt({ commit }, payload) {
            try {
                await AttemptsService.deleteAttempt(payload.data, payload.userToken);

                commit('resetAttempt');
            } catch (error) {
                console.error('Erro ao deletar tentativa', error);

                throw error;
            }
        }
    }
}