import LtiConsumersService, { deleteLtiConsumer } from "@/services/LtiConsumersService";

export default {
    state() {
        return {
            ltiConsumers: {},
            companyConsumers: {}
        }
    },

    getters: {
        getLtiConsumers: (state) => state.ltiConsumers,
        getCompanyConsumers: (state) => state.companyConsumers
    },

    mutations: {
        setLtiConsumers(state, data) {
            state.ltiConsumers = data;
        },
        setCompanyConsumers(state, data) {
            state.companyConsumers = data;
        },
        resetLtiConsumers(state) {
            console.log('resetLtiConsumers', state);
        }
    },

    actions: {
        async getLtiConsumers({ commit }, payload) {
            try {
                const response = await LtiConsumersService.getLtiConsumers(payload.data, payload.userToken);

                commit('setLtiConsumers', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar consumidores: ', error);
                throw error;
            }
        },

        async createLtiConsumer({ commit }, payload) {
            try {
                await LtiConsumersService.createLtiConsumer(payload.data, payload.userToken);

                commit('resetLtiConsumers');
            } catch (error) {
                console.error('Erro ao criar consumidor: ', error);
                throw error;
            }
        },

        async getCompanyConsumers({ commit }, payload) {
            try {
                const response = await LtiConsumersService.getLtiConsumers(payload.data, payload.userToken);

                commit('setCompanyConsumers', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar consumidores da empresa: ', error);
                throw error;
            }
        },

        async deleteLtiConsumer({ commit }, payload) {
            try {
                await deleteLtiConsumer(payload.data, payload.userToken);

                commit('resetLtiConsumers');
            } catch (error) {
                console.error('Erro ao deletar consumidor: ', error);
                throw error;
            }
        },

        async updateLtiConsumer({ commit }, payload) {
            try {
                await LtiConsumersService.updateLtiConsumer(payload.data, payload.userToken);

                commit('resetLtiConsumers');
            } catch (error) {
                console.error('Erro ao atualizar consumidor: ', error);
                throw error;
            }
        }
    }
}