import FeedbacksService from "@/services/FeedbacksService";

export default {
    state() {
        return {
            feedback: {
                text: "",
                image: "",
                quizId: ""
            },
            feedbacksData: ""
        }
    },

    mutations: {
        setFeedbackData(state, data) {
            state.feedback.text = data.text;
            state.feedback.image = data.image;
            state.feedback.quizId = data.quiz_id;
        },
        resetFeedback(state) {
            state.feedback = {
                text: "",
                image:  "",
                quizId: "",
            };
        },
        setFeedbacksData(state, data) {
            state.feedbacksData = data
        }
    },

    actions: {
        async createFeedback({ commit }, payload) {
            try {
                const response = await FeedbacksService.createFeedback(payload.feedback, payload.userToken);

                commit('resetFeedback');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar feedback:', error);

                throw new Error('Não foi possível adicionar a feedback. Por favor, tente novamente.');
            }
        },

        async findFeedback({ commit }, payload) {
            try {
                const response = await FeedbacksService.findFeedback(payload.feedbackId, payload.userToken);

                commit('setFeedbackData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar feedback:', error);

                throw new Error('Não foi possível consultar feedback. Por favor, tente novamente.');
            }
        },

        async updateFeedback({ commit }, payload) {
            try {
                const response = await FeedbacksService.updateFeedback(payload.feedback, payload.userToken);

                commit('setFeedbackData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar feedback:', error);

                throw new Error('Não foi possível atualizar a feedback. Por favor, tente novamente.');
            }
        },

        async deleteFeedback({ commit }, payload) {
            try {
                const response = await FeedbacksService.deleteFeedback(payload.feedbackId, payload.userToken);

                commit('resetFeedback');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar feedback:', error);

                throw new Error('Não foi possível deletar a feedback. Por favor, tente novamente.');
            }
        },

        async getFeedbacks({ commit }, payload) {
            try {
                const response = await FeedbacksService.getFeedbacks(payload.data, payload.userToken);

                commit('setFeedbacksData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar feedbacks:', error);

                throw new Error('Não foi possível consultar as feedbacks. Por favor, tente novamente.');
            }
        },
    }
}
