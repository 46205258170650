import MaterialsApiService from "./api/MaterialsApiService";

export const findAttempt = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.get(`/attempts/${payload.attemptId}`, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    }
    catch (error) {
        console.error('Erro ao buscar tentativa', error);

        throw error;
    }
}

export const startAttempt = async (payload, userToken) => {
    const data = {
        material_item_id: payload.materialItemId,
        user_id: payload.userId
    }

    try {
        const response = await MaterialsApiService.post('/attempts/start', data, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao iniciar tentativa', error);

        throw error;
    }
}

export const finishAttempt = async (payload, userToken) => {

    try {
        const response = await MaterialsApiService.post(`/attempts/${payload.attemptId}/finish`, [], {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao finalizar tentativa', error);

        throw error;
    }
}

export const checkAttempt = async (payload, userToken) => {
    const data = {
        material_item_id: payload.materialItemId,
    }

    try {
        const response = await MaterialsApiService.post(`/attempts/${payload.attemptId}/check`, data, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao verificar tentativa', error);

        throw error;
    }
}

export const answerAttempt = async (payload, userToken) => {
    const data = {
        material_item_id: payload.materialItemId,
        quiz_id: payload.quizId,
        question_option_id: payload.questionOptionId,
        answer: payload.answer,
    }

    try {
        const response = await MaterialsApiService.post(`/attempts/${payload.attemptId}/answer`, data, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao iniciar tentativa', error);

        throw error;
    }
}

export const deleteAttempt = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.delete(`/attempts/${payload.attemptId}`, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao deletar tentativa', error);

        throw error;
    }
}

export default {
    findAttempt,
    startAttempt,
    finishAttempt,
    checkAttempt,
    answerAttempt,
    deleteAttempt
}