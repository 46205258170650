import MaterialItemsService from "@/services/MaterialItemsService";

export default {
    state() {
        return {
            materialItem: {
                id: "",
                title: "",
                description: "",
                type: "",
                file: "",
                materialId: "",
                iframeCode: "",
                visible: "",
                quizzes: "",
                settings: "",
                isEditable: ""
            },
            materialItemData: {
                data: ""
            },
            materialItemsData: "",
            materialItemsAnswers: {},
            personalAttempts: {},
            success: ""
        }
    },

    getters: {
        getMaterialItemTitle: (state) => state.materialItem.title,
        getMaterialItemDescription: (state) => state.materialItem.description,
        getMaterialItemType: (state) => state.materialItem.type,
        getMaterialItemFile: (state) => state.materialItem.file,
        getMaterialItemMaterialId: (state) => state.materialItem.materialId,
        getMaterialItemIframeCode: (state) => state.material.iframeCode,
        getMaterialItemVisible: (state) => state.material.visible,
        getMaterialItemData: (state) => state.materialItemData.data
    },

    mutations: {
        setMaterialItemData(state, data) {
            state.materialItem.id = data.id;
            state.materialItem.title = data.title;
            state.materialItem.description = data.description;
            state.materialItem.type = data.type;
            state.materialItem.file = data.file;
            state.materialItem.materialId = data.materialId;
            state.materialItem.iframeCode = data.iframeCode;
            state.materialItem.visible = data.visible;
            state.materialItem.quizzes = data.quizzes;
            state.materialItem.settings = data.settings;
            state.materialItem.isEditable = data.is_editable;
        },
        resetMaterialItem(state) {
            state.material = {
                title: "",
                description: "",
                type: "",
                file: "",
                materialId: "",
                iframeCode: "",
                visible: ""
            };
        },
        setMaterialItemsData(state, data) {
            state.materialItemsData = data
        },
        setMaterialItemsAnswers(state, data) {
            state.materialItemsAnswers = data;
        },
        personalAttempts(state, data) {
            state.personalAttempts = data;
        },
        success: (state, message) => {
            state.success = message;
        },
    },

    actions: {
        async createMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.createMaterialItem(payload.materialItem, payload.userToken);

                commit('resetMaterialItem');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar item:', error);

                throw error;
            }
        },

        async findMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.findMaterialItem(payload.materialItemId, payload.userToken);

                commit('setMaterialItemData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar item:', error);

                throw new Error('Não foi possível consultar item. Por favor, tente novamente.');
            }
        },

        async updateMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.updateMaterialItem(payload.materialItem, payload.userToken);

                commit('setMaterialItemData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar item:', error);

                throw error;
            }
        },

        async deleteMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.deleteMaterialItem(payload.materialItemId, payload.userToken);

                commit('resetMaterialItem');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar item:', error);

                throw new Error('Não foi possível deletar a item. Por favor, tente novamente.');
            }
        },

        async getMaterialItems({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getMaterialItems(payload.data, payload.userToken);

                commit('setMaterialItemsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar items:', error);

                throw new Error('Não foi possível consultar as items. Por favor, tente novamente.');
            }
        },

        async duplicateMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.duplicateMaterialItem(payload.materialItemId, payload.userToken);

                commit('setMaterialItemData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao duplicar item:', error);

                throw new Error('Não foi possível duplicar o item. Por favor, tente novamente.');
            }
        },

        async removeQuiz({ commit }, payload) {
            try {
                const response = await MaterialItemsService.removeQuiz(payload.data, payload.userToken);

                commit('resetMaterialItem');

                return response.data;
            } catch (error) {
                console.error('Erro ao remover questão:', error);

                throw error;
            }
        },

        async getPersonalAttempts({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getAttempts(payload, payload.userToken);

                commit('personalAttempts', response.data);
            } catch (error) {
                console.error('Erro ao consultar tentativas:', error);

                throw error;
            }
        },

        async getAttempts({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getAttempts(payload, payload.userToken);

                console.log(response.data);

                commit('setMaterialItemsAnswers', response.data);
            } catch (error) {
                console.error('Erro ao consultar tentativas:', error);

                throw error;
            }
        },

        async updateMaterialItemIndex({ commit }, payload) {
            try {
                const response = await MaterialItemsService.updateIndex(payload.data, payload.userToken);

                commit('success', 'Índice atualizado com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar índice:', error);

                throw error;
            }
        },

        async uploadMaterialItemFile({ commit }, payload) {
            try {
                const response = await MaterialItemsService.uploadMaterialItemFile(payload.data, payload.userToken);

                commit('success', 'Arquivo(s) enviado(s) com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao fazer upload dos arquivos:', error);

                throw error;
            }
        },
    }
}
