import LogoutService from "@/services/LogoutService";

export default {
    mutations: {
        clearAuthData() {
            localStorage.removeItem("authToken");
        },
    },

    actions: {
        async logout({ commit }, payload) {
            try {
                const response = await LogoutService.logout(payload.userToken);

                commit("clearAuthData");

                return response.data;
            } catch (error) {
                console.log('Erro ao fazer logout:', error);

                throw new Error('Não foi possível realizar o logout. Por favor, tente novamente.');
            }
        }
    }
}