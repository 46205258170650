import MaterialsApiService from "./api/MaterialsApiService";

export const createMaterialItemSettings = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/material-item-settings', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar item', error);

        throw error;
    }
};

export const updateMaterialItemSettings = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.put(`/material-item-settings/${payload.material_item_settings_id}`, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar item', error);

        throw error;
    }
};

export default {
    createMaterialItemSettings,
    updateMaterialItemSettings
}