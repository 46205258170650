import AuthService from "./api/AuthService";

export const getMetrics = async (userToken) => {
    try {
        const response = await AuthService.get('/admin/metrics', {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao obter métricas', error);

        throw error;
    }
};

export default {
    getMetrics
}