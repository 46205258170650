import QuizApiService from "./api/QuizApiService";

export const createQuestionOption = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/question-options', {
      text: payload.text,
      image: payload.image,
      correct_answer: payload.correctAnswer,
      quiz_id: payload.quizId
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar alternativa', error);

    throw error;
  }
};

export const findQuestionOption = async (questionOptionId, userToken) => {
  try {
    const response = await QuizApiService.get('/question-options/' + questionOptionId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar alternativa', error);

    throw error;
  }
};

export const updateQuestionOption = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post(`/question-options/${payload.questionOptionId}/update`, {
      text: payload.text,
      image: payload.image,
      correct_answer: payload.correctAnswer,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar alternativa', error);

    throw error;
  }
};

export const deleteQuestionOption = async (questionOptionId, userToken) => {
  try {
    const response = await QuizApiService.delete('/question-options/' + questionOptionId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao deletar alternativa', error);

    throw error;
  }
};

export const getQuestionOptions = async (payload, userToken) => {
  try {
    let url = '/question-options?';

    if (payload.search) {
      url += `search=${payload.search}&`;
    }

    if (payload.disciplineId) {
      url += `quiz_id=${payload.quizId}&`;
    }

    const response = await QuizApiService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar alternativas:', error);
    throw error;
  }
}

export default {
  createQuestionOption,
  findQuestionOption,
  updateQuestionOption,
  deleteQuestionOption,
  getQuestionOptions,
}