import AuthService from "./api/AuthService";

export const logout = async (userToken) => {
    try {
        const response = await AuthService.post('/auth/logout', '', {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao efetuar logout:', error);

        throw error;
    }
}

export default {
    logout
}