import axios from "axios";
import store from "@/store";

const subdomain = window.location.host.split('.')[0]

const QuizApiService = axios.create({
  baseURL: process.env.VUE_APP_APPLICATION_SECURITY + subdomain + '.' + process.env.VUE_APP_ARANDU_QUIZ_URL,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

QuizApiService.interceptors.request.use(
  (config) => {
    const token = store.state.login.authToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  
  (error) => {
    return Promise.reject(error);
  }
);

export default QuizApiService;