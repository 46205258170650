import MaterialItemSettingsService from '@/services/MaterialItemSettingsService';

export default {
    state() {
        return {
            materialItemSettings: {
                startDate: "",
                endDate: "",
                attempts: "",
                value: "",
                passwordEnabled: "",
                password: "",
                feedbackEnabled: "",
                finalGradeEnabled: "",
                materialItemId: ""
            },
        }
    },

    mutations: {
        setMaterialItemSettingsData(state, data) {
            state.materialItemSettings.startDate = data.start_date;
            state.materialItemSettings.endDate = data.end_date;
            state.materialItemSettings.attempts = data.attempts;
            state.materialItemSettings.value = data.value;
            state.materialItemSettings.passwordEnabled = data.password_enabled;
            state.materialItemSettings.password = data.password;
            state.materialItemSettings.feedbackEnabled = data.feedback_enabled;
            state.materialItemSettings.finalGradeEnabled = data.final_grade_enabled;
            state.materialItemSettings.materialItemId = data.material_item_id;
        },
        resetMaterialItemSettings(state) {
            state.materialItemSettings = {
                startDate: "",
                endDate: "",
                attempts: "",
                value: "",
                passwordEnabled: "",
                password: "",
                feedbackEnabled: "",
                finalGradeEnabled: "",
                materialItemId: ""
            };
        },
        setMaterialItemsData(state, data) {
            state.materialItemsData = data
        }
    },

    actions: {
        async createMaterialItemSettings({ commit }, payload) {
            try {
                const response = await MaterialItemSettingsService.createMaterialItemSettings(payload.settings, payload.userToken);

                commit('resetMaterialItemSettings');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar item:', error);

                throw error;
            }
        },

        // async findMaterialItem({ commit }, payload) {
        //     try {
        //         const response = await MaterialItemsService.findMaterialItem(payload.materialItemId, payload.userToken);

        //         commit('setMaterialItemSettingsData', response.data);

        //         return response.data;
        //     } catch (error) {
        //         console.error('Erro ao consultar item:', error);

        //         throw new Error('Não foi possível consultar item. Por favor, tente novamente.');
        //     }
        // },

        async updateMaterialItemSettings({ commit }, payload) {
            try {
                const response = await MaterialItemSettingsService.updateMaterialItemSettings(payload.settings, payload.userToken);

                commit('setMaterialItemSettingsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar item:', error);

                throw new Error('Não foi possível atualizar a item. Por favor, tente novamente.');
            }
        },
    }
}