<template>
  <div id="app">
    <div class="loader" v-if="isLoading">
      <LoaderComponent />
    </div>
    <RouterView v-show="!isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoaderComponent from './components/loaders/LoaderComponent.vue';
import 'vue3-toastify/dist/index.css';

export default {

  components: {
    LoaderComponent
  },
  computed: {
    ...mapGetters(['isLoading'])
  }
};
</script>

<style scoped>
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--blue-5);
  z-index: 1000;
}
</style>