import DisciplineInscriptionService from "@/services/DisciplineInscriptionService";

export default {
    state() {
        return {
            disciplineInscriptions: {},
            status: "",
            success: false
        }
    },

    getters: {
        getDisciplineInscription: (state) => state.disciplineInscriptions
    },

    mutations: {
        setDisciplineInscription(state, data) {
            state.disciplineInscriptions = data;
        },
        resetDisciplineInscription(state) {
            state.disciplineInscriptions = {};
        },
        success(state, status) {
            state.status = status;
        }
    },

    actions: {
        async getDisciplineInscriptions({ commit }, payload) {
            try {
                const response = await DisciplineInscriptionService.getDisciplineInscriptions(payload.data, payload.userToken);

                commit('setDisciplineInscription', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar inscrição em disciplina:', error);
                throw error;
            }
        },

        async createDisciplineInscription({ commit }, payload) {
            try {
                const response = await DisciplineInscriptionService.createDisciplineInscription(payload.data, payload.userToken);

                commit('success', 'Inscrição realizada com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar inscrição em disciplina:', error);
                throw error;
            }
        },

        async deleteDisciplineInscription({ commit }, payload) {
            try {
                await DisciplineInscriptionService.deleteDisciplineInscription(payload, payload.userToken);

                commit('success, Inscrição deletada com sucesso!');
            } catch (error) {
                console.error('Erro ao deletar inscrição em disciplina:', error);
                throw error;
            }
        },

        async updateDisciplineInscription({ commit }, payload) {
            try {
                const response = await DisciplineInscriptionService.updateDisciplineInscription(payload.data, payload.userToken);

                commit('success', 'Inscrição atualizada com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar inscrição em disciplina:', error);
                throw error;
            }
        }
    }
}
