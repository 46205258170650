import store from "@/store";
import AuthService from "./api/AuthService";

export const login = async (credentials) => {
  try {
    const response = await AuthService.post('/auth/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Erro ao efetuar login:', error);
    throw error;
  }
};

export const checkToken = async () => {
  try {
    const token = store.state.user.authToken;
    if (token == "") return false;

    const data = await AuthService.get("auth/login", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch ({ response }) {
    return response;
  }
};

export const forgotPassword = async (credentials) => {
  try {
    const response = await AuthService.post('/auth/forgot-password', credentials);
    return response.data;
  } catch (error) {
    console.log("Erro ao recuperar senha: ", error);
    throw error;
  }
};

export default {
  login,
  checkToken,
  forgotPassword
}

