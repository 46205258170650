import MaterialsApiService from "./api/MaterialsApiService";

export const getGrades = async (payload, userToken) => {
    try {
        let url = '/grades?';

        if (payload.disciplineId) {
            url += 'discipline_id=' + payload.disciplineId + '&';
        }

        if (payload.materialItemId) {
            url += 'material_item_id=' + payload.materialItemId + '&';
        }

        if (payload.studentId) {
            url += 'student_id=' + payload.studentId + '&';
        }

        if (payload.name) {
            url += 'name=' + payload.name + '&';
        }

        if (payload.perPage) {
            url += 'per_page=' + payload.perPage + '&';
        }

        if (payload.page) {
            url += 'page=' + payload.page + '&';
        }

        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar notas', error);

        throw error;
    }
}

export const updateGrade = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.put(`/grades/${payload.gradeId}`, payload, {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar nota', error);

        throw error;
    }
}

export default {
    getGrades,
    updateGrade
}
