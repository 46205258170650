import AdminService from "@/services/AdminService";

export default {
    state() {
        return {
            companies: {
                total: "",
                active: "",
                inactive: ""
            },
            users: {
                total: ""
            }
        }
    },

    getters: {
        getCompaniesMetrics: (state) => state.companies,
        getUsersMetrics: (state) => state.users
    },

    mutations: {
        setCompaniesMetrics(state, data) {
            state.companies.total = data.total,
            state.companies.active = data.active,
            state.companies.inactive = data.inactive
        },
        setUsersMetrics(state, data) {
            state.users.total = data.total
        }
    },

    actions: {
        setCompaniesMetrics({ commit }, data) {
            commit("setCompaniesMetrics", data);
        },

        setUsersMetrics({ commit }, data) {
            commit("setUsersMetrics", data);
        },

        async getMetrics({ commit }, payload) {
            try {
                const response = await AdminService.getMetrics(payload.userToken);

                commit('setCompaniesMetrics', response.companies);

                commit('setUsersMetrics', response.users);

                return response;
            } catch(error) {
                console.log('Erro ao obter métricas:', error);

                throw new Error('Não foi possível obter métricas. Por favor, tente novamente.')
            }
        }
    }
}